export default{
    config: {
        apiKey: "AIzaSyAchU5_jEQLrkJOt7rHaA_j2IuAjlsqMC0",
        authDomain: "sourdoughcalc.firebaseapp.com",
        databaseURL: "https://sourdoughcalc-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "sourdoughcalc",
        storageBucket: "sourdoughcalc.appspot.com",
        messagingSenderId: "1035705875810",
        appId: "1:1035705875810:web:98509ea132d409e3ee803b",
        measurementId: "G-094FY5J9FV"
    }
}