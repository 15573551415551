import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'

// Plugins
import { registerPlugins } from '@/plugins'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import VueScrollTo from 'vue-scrollto'

//firebase
import { initializeApp } from "firebase/app"
import { getMessaging, getToken } from "firebase/messaging"
import { getAuth, onAuthStateChanged, signInWithPopup, FacebookAuthProvider } from "firebase/auth";
// import { initializeFirestore, CACHE_SIZE_UNLIMITED } from "firebase/firestore";

// const firestoreDb = initializeFirestore(app, {
//   cacheSizeBytes: CACHE_SIZE_UNLIMITED
// });

const config = {
    apiKey: "AIzaSyAchU5_jEQLrkJOt7rHaA_j2IuAjlsqMC0",
    authDomain: "sourdoughcalc.firebaseapp.com",
    databaseURL: "https://sourdoughcalc-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "sourdoughcalc",
    storageBucket: "sourdoughcalc.appspot.com",
    messagingSenderId: "1035705875810",
    appId: "1:1035705875810:web:98509ea132d409e3ee803b",
    measurementId: "G-094FY5J9FV"
  } // 4. Get Firebase Configuration
const firebaseApp = initializeApp(config)

const messaging = getMessaging(firebaseApp)

Notification.requestPermission().then(() => {
  //console.log('Notification permission granted.')
})

const firebaseToken = getToken(messaging, {vapidKey: "BJtF_k_RSwbLbrxVFZwiffKRdEfEOrHR-VUlid5kJo8WF_Zy8edPqBSi78Pkb6TzKdhC677ChzLxUORTNW40Ang"})
// Get Token
firebaseToken
  .then((currentToken) => {
    if (currentToken) {
      localStorage.setItem('firebaseToken',currentToken)
      //console.log("FirebaseToken: "+currentToken)
    } else {
      //console.log('No registration token available. Request permission to generate one.');
    }
  }).catch((err) => {
    //console.log('An error occurred while retrieving token. ', err);
  })

  const provider = new FacebookAuthProvider();
  provider.addScope('email');
  const auth = getAuth();
  onAuthStateChanged(auth, (firebaseUser) => {
    // Check if we are already signed-in Firebase with the correct user.
    if (!firebaseUser) {
      signInWithPopup(auth, provider)
        .then((result) => {
          /* @type {firebase.auth.OAuthCredential} */
          const credential = FacebookAuthProvider.credentialFromResult(result)
          //console.log("facebook credential "+credential)
          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          const token = credential.accessToken
          //console.log("facebook token "+token)
          // The signed-in user info, it will give you all basic info of logged-in user
          const user = result.user
          localStorage.setItem('userEmail',user.email)
          localStorage.setItem('userFullName',user.displayName)
          //console.log("facebook user "+user.email+" "+user.displayName)
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code
          //console.log("facebook errorCode "+errorCode)
          const errorMessage = error.message
          //console.log("facebook errorMessage "+errorMessage)
          // The email of the user's account used.
          const email = error.email
          //console.log("facebook error email "+email)
          // The firebase.auth.AuthCredential type that was used.
          const credential = FacebookAuthProvider.credentialFromError(error)
          //console.log("facebook error credential "+credential)
        
        });
    } else {
      // User is already signed-in Firebase with the correct user.
      //console.log("User is already signed-in Firebase with the correct user "+firebaseUser.email)
      localStorage.setItem('userEmail',firebaseUser.email)
      localStorage.setItem('userFullName',firebaseUser.displayName)
    }
  });
  
  const status = navigator.permissions.query({
    name: 'periodic-background-sync',
  });
  if (status.state === 'granted') {
    // Periodic background sync can be used.
    //console.log("sync can be used")
  } else {
    // Periodic background sync cannot be used.
    //console.log("sync cannot be used")
  }
  navigator.serviceWorker.ready.then(async registration => {
    try {
      registration.periodicSync.register('content-sync', { minInterval: 24 * 60 * 60 * 1000 });
        //console.log('Periodic background sync registered.');
      } catch (err) {
        console.error(err.name, err.message);
      }
    });
    const registration =  navigator.serviceWorker.ready;
    if ('periodicSync' in registration) {
      const tags = registration.periodicSync.getTags();
      // Only update content if sync isn't set up.
      if (!tags.includes('content-sync')) {
        updateContentOnPageLoad();
      }
    } else {
      // If periodic background sync isn't supported, always update.
      updateContentOnPageLoad();
    }
    self.addEventListener('periodicsync', (event) => {
      if (event.tag === 'content-sync') {
        event.waitUntil(updateContentOnPageLoad());
      }
    });
    async function updateContentOnPageLoad() {
      const appCache =  caches.open('sd-calc');
      //await appCache.add('/api/articles');
    }

const vuetify = createVuetify({
    components,
    directives,
  })

const app = createApp(App)

registerPlugins(app)
app.use(VueScrollTo)
app.use(vuetify)

app.mount('#app')