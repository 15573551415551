<template>
  <router-view />

  <v-layout>
    <v-app-bar>
        <template v-slot:image>
          <v-img
            gradient="to top right, #856B5C, #F5D9D8"
          ></v-img>
        </template>

        <template v-slot:prepend>
          <v-menu transition="scroll-y-transition">
            <template v-slot:activator="{ props }">
              <v-btn
                color="white"
                class="ma-2"
                v-bind="props">
                <v-app-bar-nav-icon> </v-app-bar-nav-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <router-link to="#" v-scroll-to="'#home'" class="menu" >
                  <v-list-item-title v-text="'Home'"></v-list-item-title>
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link to="#" v-scroll-to="'#setup'" class="menu" >
                  <v-list-item-title v-text="'Set Up'"></v-list-item-title>
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link to="#" v-scroll-to="'#recipes'" class="menu" >
                  <v-list-item-title v-text="'Recipes'"></v-list-item-title>
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link to="#use" @click="openUse" class="menu" >
                  <v-list-item-title v-text="'How to Use'"></v-list-item-title>
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link to="" @click="logout" class="menu" >
                  <v-list-item-title v-text="'Logout'"></v-list-item-title>
                </router-link>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <v-app-bar-title>Sourdough Calc</v-app-bar-title>

        <!-- <template v-slot:append>
          <v-btn icon="mdi-dots-vertical"></v-btn>
        </template> -->
      </v-app-bar>

    <Transition name="modal">
      <div v-if="show" id="use" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">How to Use</slot>
            </div>

            <div class="modal-body">
              <slot name="body">
                <b>SourdoughCalc by BSMapps</b> is a useful <b>sourdough bread calculator and recipe notepad</b>. 
                <p>It will help you calculate the analogies of basic ingredients and you can see the effects in hydration, inoculation, salt, total dough weight and suggests no of loaves.</p>
                <p>The calculation is using quantities and adjusts percentages. By adjusting these you can get the desired values and after that you can save your recipe including some notes with additional making and baking information.</p>
                <p>Recipes can be edited before saving in the cloud.</p>
                <p>The basic screen shows the calculator, where you enter quantities in grams, calculate vital percentages and save as recipes. After entering the quantities of the various flours, sourdough, sourdough hydration percent and water everything else is calculated. Change values to adjust your parameters. When pressing the <b>Save</b> button the percentages are saved. You can add a title, add or modify your ingredients and some additional notes about the making and the baking of your recipe and then <b>Save</b>.</p>
                <p>As soon as you Save your recipe you are directed to the list of Recipes sorted in a date-time order. Recipes are never overwritten.</p>
                <p>You can view a recipe by pressing on its title, or, by using the relevant icons, to delete it or edit it. If you edit a recipe, you can change the texts and when you Save it, a new one will be created with a new date.You can use the settings screen to define five flours you use mostly, for example All Purpose, Semola Remachinata as well as your typical procedure of making of your bread. You can save these and recall and edit when you require. These will be used as names for the calculator and recipe generated, as well as the notes template you have created.</p>
                <p>When you pressed on recipes's title a popup window will come up and you can copy and paste the text wherever you like.</p>
                <p>***Important Note: Please enable notifications and popup windows for the webapp in order to receive important information and be able to login using Facebook.</p> 
                <p>***Important Note: In order to save your recipe in your own private area in the cloud you need to use your Facebook account. If you have already logged in to Facebook you do not need to login, but if you have not being logged in you will be prompted to do so. </p> 
                <p>For all your comments or information please contact <b>support@bsmapps.com</b>.</p>
                <p><small>SourdoughCalc is a BSMapps<sup><small>TM</small></sup>- Athens, Greece</small></p>
              </slot>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="closeUse">OK</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
  </Transition>
    <v-bottom-navigation  color="#FFFFFF">
      <router-link to="#" v-scroll-to="'#home'">
      <v-btn value="home">
        <v-icon>mdi-home</v-icon>
        Home</v-btn>
      </router-link>
      <router-link to="#" v-scroll-to="'#setup'">
        <v-btn value="setup">
          <v-icon>mdi-cog</v-icon>
          Set Up</v-btn>
      </router-link>
      <router-link to="#" v-scroll-to="'#recipes'">
        <v-btn value="recipes">
          <v-icon>mdi-baguette</v-icon>
        Recipes
        </v-btn>
      </router-link>
      <router-link to="#" v-scroll-to="'#about'">
        <v-btn value="about">
          <v-icon>mdi-information</v-icon>
        About</v-btn>
      </router-link>
        
    </v-bottom-navigation>
  </v-layout>
</template>


<script>
  import { onMessage } from '@firebase/messaging'
  import {getMessagingObject} from './firebase/messaging'
  import { getAuth, signOut } from "firebase/auth";


  export default{
    data() {
      return{
        show: false,
        firebaseToken : '',
        notification : {
          body : '',
          title : '',
        }
      }
    },
    methods: {
      openUse(){
        this.show = !this.show
      },
      closeUse(){
        this.show = !this.show
      }, 
      logout(){
        const auth = getAuth();
        signOut(auth).then(() => {
          // Sign-out successful.
          //console.log('Sign-out successfully')
        }).catch((error) => {
          // An error happened.
          //console.log('Sign-out failed '+error)
        });
      }
    },
  async created(){
    //* Triggered when user is on this page
    let messaging = getMessagingObject();
    onMessage(messaging, (payload) => {
      const {notification} = payload
      this.notification.body = notification.body
      this.notification.title = notification.title
      //console.log(notification)
    })
  },
}
</script>


<style>
a{
  text-decoration: none;
  color: #FFFFFF;
}
a :hover, a :active, .v-btn--active{
  background-color: #856B5C !important;
  
}
a.menu {
  color: #856B5C;
}
a.menu :hover, a.menu :active{
  padding: 5px;
  background-color:transparent !important;
}
.v-toolbar-title{
  color:#ffffff;
}
.v-app-bar .v-btn, .text-secondary, .v-btn--variant-text .v-btn__overlay {
  background-color:transparent !important;
}
.v-icon, v-icon :hover{
  /*color: #FFFFFF;*/
  background-color: transparent !important;
}
.v-application__wrap {
    align-items: center;
    background-color: #856b5c;
}
.v-container{
  margin-left: 0 !important;
}
.v-row{
  flex-wrap: nowrap;
}
.v-bottom-navigation__content{
  background: rgb(133,107,92);
    background: linear-gradient(182deg, rgba(133,107,92,1) 0%, rgba(133,107,92,1) 0%, rgba(245,217,216,1) 100%);
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    max-height: 500px;
    width:80%;
    margin: 0 auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 33%);
    transition: all .3s ease;
    overflow-y: auto;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
  color: #856B5C !important;
  font-weight: bold;
}
.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>